<template>
  <v-container fluid class="px-lg-5">
    <app-modal
      v-show="isModalVisible"
      :modal="modal"
      @submit-modal="submitModal"
      @text-changed="onDataChange"
      @time-changed="onDataChange"
    />
    <app-header
      :title="title"
      :breadcrumbs="breadcrumbs"
      :actionButton="actionButton"
      @clicked="openCreateOfficeModal"
      :showButton="true"
    />
    <v-card class="rounded-card" flat outlined>
      <v-data-table
        :headers="headers"
        :items="offices.items"
        :options.sync="options"
        @update:options="updateOptions"
        :server-items-length="offices.count"
        :loading="loading" flat
        :no-data-text="$t('messages.emptyState', { entity: $tc('models.office.entity') })"
        :no-results-text="$t('messages.emptyState', { entity: $tc('models.office.entity') })">
        <template v-slot:[`item.businessHoursStart`]="{ item }">
          {{ moment(item.businessHoursStart, 'HH:mm').format('hh:mm A') }} - {{
            moment(item.businessHoursEnd, 'HH:mm').format('hh:mm A') }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small @click="editOffice(item)">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
          <v-btn icon small @click="deleteOffice(item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment-timezone';

export default {
  data() {
    return {
      moment,
      loading: false,
      isModalVisible: false,
      modal: {
        show: false,
        id: '',
        behaviour: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          name: {
            type: 'text',
            key: 'name',
            value: '',
            label: this.$t('models.office.attributes.name'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          timezone: {
            type: 'select',
            key: 'timezone',
            value: '',
            placeholder: this.$t('models.office.attributes.timezone'),
            label: this.$t('models.office.attributes.timezone'),
            rules: [{name: 'requiredSelect'}],
            options: Object.values(moment.tz.names()),
            itemText: 'text',
            itemValue: 'key',
            classes: 'col-12 py-0',
            serverErrors: []
          },
          weekends: {
            type: 'select',
            multiple: true,
            key: 'weekends',
            value: '',
            placeholder: this.$t('models.office.attributes.weekends'),
            label: this.$t('models.office.attributes.weekends'),
            options: Object.values(moment.weekdays()),
            itemText: 'text',
            itemValue: 'key',
            classes: 'col-12 py-0',
            serverErrors: []
          },
          fullTimeWorkingDay: {
            type: 'number',
            key: 'fullTimeWorkingDay',
            value: '',
            trackable: true,
            label: this.$t('models.office.attributes.fullTimeWorkingDay'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          businessHoursStart: {
            type: 'timepicker',
            key: 'businessHoursStart',
            value: '',
            label: this.$t('models.office.attributes.businessHoursStart'),
            placeholder: this.$t('models.office.attributes.businessHoursStart'),
            rules: [{name: 'required'}],
            trackable: true,
            classes: 'col-lg-6 col-md-12 py-0',
            serverErrors: []
          },
          businessHoursEnd: {
            type: 'timepicker',
            key: 'businessHoursEnd',
            value: '',
            disabled: true,
            label: this.$t('models.office.attributes.businessHoursEnd'),
            placeholder: this.$t('models.office.attributes.businessHoursEnd'),
            rules: [],
            classes: 'col-lg-6 col-md-12 py-0',
            serverErrors: []
          },
          googleCalendarId: {
            type: 'text',
            key: 'googleCalendarId',
            value: '',
            label: this.$t('models.office.attributes.googleCalendarId'),
            classes: 'col-12 py-0',
            serverErrors: []
          }
        }
      },
      title: {
        text: this.$t('layout.mainMenu.offices'),
        icon: 'mdi-office-building'
      },
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.company'),
          to: {name: 'Offices'},
          exact: true
        },
        {
          text: this.$t('layout.mainMenu.offices'),
          to: {name: 'Offices'},
          exact: true
        }
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      },
      actionButton: {
        text: this.$t('actions.create', {entity: this.$tc('models.office.entity', 1)}),
        icon: 'mdi-plus'
      },
      headers: [
        {
          text: '',
          value: 'id',
          align: ' d-none'
        },
        {
          text: this.$t('models.office.attributes.name'),
          value: 'name'
        },
        {
          text: this.$t('models.office.attributes.timezone'),
          value: 'timezone'
        },
        {
          text: this.$t('models.office.attributes.weekends'),
          value: 'weekends'
        },
        {
          text: this.$t('models.office.attributes.fullTimeWorkingDay'),
          value: 'fullTimeWorkingDay'
        },
        {
          text: this.$t('models.office.attributes.businessHours'),
          value: 'businessHoursStart'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '120px'
        }
      ]
    };
  },
  computed: mapGetters(['offices', 'isAdmin']),
  methods: {
    ...mapActions([
      'getOffices',
      'destroyOffice',
      'createOffice',
      'updateOffice'
    ]),
    updateOptions(options) {
      this.loading = true;
      this.getOffices(options).then(() => (this.loading = false));
    },
    submitModal(data) {
      const request = this.modal.behaviour === 'createOffice' ? this.createOffice(data) :
        this.updateOffice({officeId: this.modal.id, form: data});

      request.then((response) => {
        if (this.successCode(response.status)) {
          this.modal.show = false;
          this.updateOptions(this.options);
        } else {
          this.displayInlineServerErrors(this.modal.fields, response.errors);
        }
      });
    },
    editOffice(data) {
      this.clearInlineServerErrors(this.modal.fields);
      this.modal.behaviour = 'updateOffice';
      this.modal.title = this.$t('views.offices.updateOffice');
      this._.forEach(this.modal.fields, (field) => {
        this.modal.fields[field.key].value = data[field.key];
      });
      this.modal.id = data.id;
      this.modal.show = true;
    },
    openCreateOfficeModal() {
      this.modal.resetForm = true;
      this.modal.title = this.$t('views.offices.newOffice');
      this._.forEach(this.modal.fields, (field) => {
        this.modal.fields[field.key].value = '';
      });
      this.modal.behaviour = 'createOffice';
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    onDataChange($event) {
      if ($event.key === 'businessHoursStart' || $event.key === 'fullTimeWorkingDay') {
        if (this.modal.fields.businessHoursStart.value && this.modal.fields.fullTimeWorkingDay.value) {
          this.modal.fields.businessHoursEnd.value = moment(this.modal.fields.businessHoursStart.value, 'HH:mm')
            .add(this.modal.fields.fullTimeWorkingDay.value, 'hours').format('HH:mm');
        } else {
          this.modal.fields.businessHoursEnd.value = null;
        }
      }
    },
    deleteOffice(office) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: office.name}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyOffice(office.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    }
  }
};
</script>
